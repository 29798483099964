import React from 'react'

import { Link } from 'gatsby'
import { SectionCenter } from '../components/styled'
import WpMainMenu from '../components/wp-main-menu'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { trackBetaCampaign } from '../helpers/campaigns'

const NotFoundPage = () => (
  <>
    <WpMainMenu menuBg={true} />
    <Layout>
      <SEO title="404: Niet gevonden" />
      
      <SectionCenter>
      <h1>404: Pagina is er niet...</h1>
      <p>De pagina die je probeert op te vragen lijkt is op dit moment niet beschikbaar te zijn. Je zou de <Link to="/">homepage</Link> eens kunnen proberen. Of meld je direct aan voor ons <Link onClick={() => trackBetaCampaign('404 Page')} to="/beta">beta programma</Link>.</p>
      </SectionCenter>
    </Layout>
  </>
)

export default NotFoundPage
